<template>
    <h2>导航栏</h2>
</template>

<script>

export default {
    data() {
        // 数据属性
        return {

        }
    },
    // 注入组件
    components: {

    },
    // 方法
    methods: {

    },
    // 计算属性
    computed: {

    },
}
</script>

// 样式
<style scoped></style>