<template>
  <el-header>
    <Header />
  </el-header>
  <el-main>
    <UploadImage />
  </el-main>
  <el-footer>
    <Footer />
  </el-footer>
</template>

<script>
import UploadImage from './components/UploadImage.vue'
import Header from './pages/Header.vue'
import Footer from './pages/Footer.vue'
export default {
  name: 'App',
  components: {
    UploadImage,
    Header,
    Footer,
  }
}
</script>
<style>
body {
  font-family: Arial, sans-serif;
  /* 字体设置 */
  background-color: #ffffff;
  /* 背景颜色 */
}

/* 移动端适配 */
@media only screen and (max-width: 768px) {

  .el-main {
    padding: 5px
  }

}
</style>