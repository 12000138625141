<template>
    <div class="upload-image">

        <div class="upload-area" v-if="isUploadBoxVisible">
            <!-- 拖拽上传区域开始 -->
            <div class="drop-zone" id="dropzone" @drop.prevent="handleDrop" @dragover.prevent
                @click="$refs.imageUpload.click()">
                <!-- 显示上传提示和图标 -->
                <img src="../assets/addpicture.svg" alt="">
                <div class="upload_text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="upload_tip">只能上传jpg/png/jpeg/gif/svg/ico文件，且不超过10MB</div>
                <!-- 隐藏的文件输入框，用于接受文件选择并触发上传 -->
                <input type="file" accept="image/jpg,image/jpeg,image/ico,image/gif,image/svg,image/png"
                    ref="imageUpload" @change="handleFileChange" style="display:none" />
            </div>
            <!-- 拖拽上传区域结束 -->
            <div class="compress-image">
                <canvas ref="canvas" style="display: none;"></canvas>
                <span class="compress-mage-lv">压缩等级<em>(默认的压缩比例为 0.8)</em>：</span>
                <el-radio-group v-model="radio" @change="compressionRatio">
                    <el-radio :label="0.5">0.5</el-radio>
                    <el-radio :label="0.6">0.6</el-radio>
                    <el-radio :label="0.8">0.8</el-radio>
                    <el-radio :label="0.9">0.9</el-radio>
                </el-radio-group>
            </div>
        </div>

        <!-- 显示图片预览和文件信息的容器，只有在isImagePreviewVisible为true时才显示 -->
        <div v-if="isImagePreviewVisible" class="upload-result">
            <!-- 文件信息区域，显示已选择的文件名和上传按钮 -->
            <p class="file-info">
                已选择文件:
                <!-- 用于触发图片上传的按钮 -->
                <el-tooltip class="item" effect="dark" content="上传图片" placement="bottom">
                    <button class="upload-button" @click="uploadImage"><i class="el-icon-upload"></i></button>
                </el-tooltip>
                <!-- 用于触发图片删除的按钮 -->
                <el-tooltip class="item" effect="dark" content="删除图片" placement="bottom-end">
                    <button class="delete-button" @click="clearSelectedFile"><i class="el-icon-delete"></i></button>
                </el-tooltip>
                <!-- 显示当前选中的文件名 -->
                <span class="highlight">{{ selectedFileName }}</span>
            <p>原始大小: {{ originalSize }}</p>
            <p>压缩后大小: {{ compressedSize }}</p>
            </p>
            <!-- 图片预览区域，如果选中的文件是图片，则会在这里显示预览 -->
            <div class="demo-image__placeholder">
                <div class="block">
                    <el-image :src="previewImage">
                        <template #placeholder>
                            <div class="image-slot">加载中<span class="dot">...</span></div>
                        </template>
                    </el-image>
                </div>
            </div>
        </div>

        <!-- 显示上传结果的模块，只有在isUploadResultVisible为true时才会显示 -->
        <div v-if="isUploadResultVisible" class="upload-return-result">
            <h3>上传返回结果：</h3>
            <el-tooltip class="item" effect="dark" content="继续上传" placement="left">
                <button class="continue-button" @click="continueUploading"><i class="el-icon-back"></i></button>
            </el-tooltip>
            <!-- 返回图像预览 -->
            <div class="demo-image__placeholder return-picture">
                <div class="block">
                    <el-image :src="uploadUrl">
                        <template #placeholder>
                            <div class="image-slot">加载中<span class="dot">...</span></div>
                        </template>
                    </el-image>
                </div>
            </div>
            <!-- 上传结果详细信息 -->
            <div class="return-content">
                <p>文件名： <!-- 文件名展示及复制功能 -->
                    <span class="hidden">{{ uploadName }}</span>
                    <!-- 复制文件名按钮，点击将文件名复制到剪贴板 -->
                    <el-tooltip class="item" effect="dark" content="复制" placement="bottom-end">
                        <button class="copy-button" :data-clipboard-text="uploadName" v-html="copyButton"></button>
                    </el-tooltip>
                    <!-- 文件名高亮显示 -->
                    <span class="highlight">{{ uploadName }}</span>
                </p>
                <p>URL： <!-- 文件URL展示及复制功能 -->
                    <span class="hidden">{{ uploadUrl }}</span>
                    <!-- 复制文件URL按钮，点击将文件URL复制到剪贴板 -->
                    <el-tooltip class="item" effect="dark" content="复制" placement="bottom-end">
                        <button class="copy-button" :data-clipboard-text="uploadName" v-html="copyButton"></button>
                    </el-tooltip>
                    <!-- 文件URL高亮显示 -->
                    <span class="highlight">{{ uploadUrl }}</span>
                </p>
                <p>路径： <!-- 文件路径展示及复制功能 -->
                    <span class="hidden">{{ uploadPath }}</span>
                    <!-- 复制文件路径按钮，点击将文件路径复制到剪贴板 -->
                    <el-tooltip class="item" effect="dark" content="复制" placement="bottom-end">
                        <button class="copy-button" :data-clipboard-text="uploadName" v-html="copyButton"></button>
                    </el-tooltip>
                    <!-- 文件路径高亮显示 -->
                    <span class="highlight">{{ uploadPath }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import ClipboardJS from 'clipboard'
import { Notification } from 'element3'
import { ElLoading } from 'element3'
export default {
    data() {
        return {
            // 上传框组件
            isUploadBoxVisible: true, // 管理界面上传框的可见性
            // 图片预览组件
            isImagePreviewVisible: false, // 图片预览的可见性管理
            imageCompressionRatio: 0.8, // 默认的压缩比例
            selectedFileName: '', // 存储用户选择的文件名
            originalSize: '', // 图片原始大小
            compressedSize: '', // 图片压缩后大小
            previewImage: '', // 存储图片预览的base64数据
            // 上传结果组件
            isUploadResultVisible: false, // 上传结果的界面管理
            uploadName: '', // 存储上传后的文件名
            uploadUrl: '',  // 存储上传后的URL
            uploadPath: '', // 上传文件的存储路径
            copyButton: '<i class="el-icon-copy-document"></i>', // 复制按钮的HTML结构
        };
    },
    methods: {
        /**
         * 处理文件拖放事件。
         * @param {DragEvent} e - 拖放事件对象。
         * @description 阻止默认的拖放行为，提取并处理拖放到区域内的文件。
         */
        handleDrop(e) {
            e.preventDefault(); // 阻止浏览器默认的文件拖放行为
            const files = e.dataTransfer.files; // 获取拖放的文件数组
            if (files.length > 1) {
                Notification.error({
                    title: '错误',
                    message: '只能上传一个文件，默认选择第一个文件',
                });
            }

            if (files.length > 0) {
                // 当有文件被拖放时，调用handleFileChange方法进行文件处理
                this.handleFileChange({ target: { files } });
            }
        },

        /**
         * 处理文件选择变化的事件
         * @param {Event} event - 触发事件的事件对象，预期为input的change事件对象
         * 该函数不返回任何内容，但会更新组件的多个属性，包括压缩后的图片数据URL、原始文件大小、预览图片等
         */
        async handleFileChange(event) {
            try {
                const file = event.target.files[0]; // 获取用户选择的文件
                if (!file) {
                    throw new Error('请选择文件'); // 如果没有选择文件，抛出错误
                }
                if (!file.type.startsWith('image/')) {
                    throw new Error('只允许上传图片文件'); // 如果文件类型不是图片，抛出错误
                }
                if (file.size > 10485760) {
                    throw new Error('文件大小不能超过10MB'); // 如果文件大小超过10MB，抛出错误
                }

                // 压缩图片并获取压缩后的图片的Data URL
                const compressedDataURL = await this.compressImage(file, this.imageCompressionRatio);
                // 更新组件状态，包括压缩后的文件大小、原始文件大小、预览图片等
                this.compressedSize = this.calculateBase64Size(compressedDataURL);
                this.originalSize = this.bytesToSize(file.size);
                this.previewImage = compressedDataURL;
                this.selectedFileName = file.name;
                this.isUploadBoxVisible = false;
                this.isImagePreviewVisible = true;
            } catch (error) {
                this.handleError(error); // 捕获并处理在处理文件选择过程中的任何错误
            }
        },

        /**
         * 处理错误信息并显示通知。
         * @param {Object} error 包含错误信息的对象，通常是从API响应中获取的。
         * 预期包含一个message属性，用于显示具体的错误信息。
         */
        handleError(error) {
            // 使用Notification组件显示一个错误通知
            Notification.error({
                title: '错误', // 设置通知标题为"错误"
                message: error.message // 设置通知内容为错误对象中的message属性
            });
        },

        /**
         * 设置图片压缩比
         * @param {number} value - 压缩比的值，必须是0.1到1之间的有限数字。
         * @throws 会抛出错误如果value不是指定范围内的有限数字。
         */
        compressionRatio(value) {
            try {
                // 验证传入的value是否满足要求的条件
                if (typeof value !== 'number' || isNaN(value) || !isFinite(value) || value < 0.1 || value > 1) {
                    throw new Error('压缩比必须是一个在0.1到1之间的有限数字');
                }
                this.imageCompressionRatio = value;
            } catch (error) {
                // 错误处理：将捕获到的错误交由handleError方法处理
                this.handleError(error);
            }
        },

        /**
         * 压缩图片。
         * @param {File} file - 待压缩的图片文件。
         * @param {number} compressionRatio - 压缩比例，必须在0和1之间。
         * @returns {Promise<string>} 返回一个Promise，成功时解析为压缩后的图片DataURL，失败时reject一个错误对象。
         */
        async compressImage(file, compressionRatio) {
            // 检查压缩比例的有效性
            if (compressionRatio < 0 || compressionRatio > 1) {
                return Promise.reject(new Error('压缩比例无效，必须在0和1之间'));
            }

            return new Promise(async (resolve, reject) => {
                await this.$nextTick(); // 确保在DOM更新后执行

                const canvas = this.$refs.canvas;
                if (!canvas) {
                    reject(new Error('无法找到canvas元素'));
                    return;
                }
                const ctx = canvas.getContext('2d');
                const img = new Image();

                img.onload = () => {
                    try {
                        // 计算并设置压缩后的图片尺寸，保持宽高比，最大宽度为3440px
                        const maxWidth = 3440;
                        let width = img.width;
                        let height = img.height;

                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }

                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(img, 0, 0, width, height);

                        // 压缩图片并返回DataURL
                        const compressedDataURL = canvas.toDataURL('image/jpeg', compressionRatio);
                        resolve(compressedDataURL);

                        // 清除canvas内容，虽然不是必须的，但有助于保持良好的编程习惯
                        ctx.clearRect(0, 0, canvas.width, canvas.height);
                    } catch (error) {
                        reject(new Error('图片压缩过程中发生错误: ' + error.message));
                    } finally {
                        // 释放资源
                        URL.revokeObjectURL(img.src);
                    }
                };

                img.onerror = (error) => {
                    // 图片加载失败的处理，包括资源释放
                    reject(new Error('图片加载失败: ' + file.name + ' 错误详情: ' + error.message));
                    URL.revokeObjectURL(img.src); // 确保在加载失败时也释放资源
                };

                // 设置img的源为file
                img.src = URL.createObjectURL(file);
            });
        },

        /**
         * 将字节转换为可读的文件大小格式
         * @param {number} bytes 要转换的字节数
         * @returns {string} 转换后的文件大小字符串，格式为整数或两位小数的B、KB或MB
         */
        bytesToSize(bytes) {
            // 如果字节数小于1024，直接以字节（B）为单位返回
            if (bytes < 1024) {
                return `${Math.round(bytes)} B`;
            } else if (bytes < 1024 * 1024) {
                // 如果字节数在1024和1024*1024之间，转换为以千字节（KB）为单位，并四舍五入到小数点后两位
                return `${Math.round(bytes / 1024 * 100) / 100} KB`;
            } else {
                // 如果字节数大于1024*1024，转换为以兆字节（MB）为单位，并四舍五入到小数点后两位
                return `${Math.round(bytes / (1024 * 1024) * 100) / 100} MB`;
            }
        },

        /**
         * 计算Base64字符串的大小。
         * 该函数首先从给定的Base64字符串中提取有效负载部分，然后根据Base64编码规则计算出原始数据的字节大小。
         * 最后，将字节大小转换为易读的格式（如KB、MB等）并返回。
         * 
         * @param {string} base64String 一个完整的Base64字符串，包含头部和有效负载。
         * @return {string} 返回转换后的数据大小字符串。
         */
        calculateBase64Size(base64String) {
            // 从Base64字符串中提取有效负载部分
            const base64Payload = base64String.split(',')[1];
            // 计算Base64有效负载的字节大小（四舍五入向上取整）
            const sizeInBytes = Math.ceil(base64Payload.length * 0.75);
            // 调用bytesToSize方法，将字节大小转换为易读格式并返回
            return this.bytesToSize(sizeInBytes);
        },

        /**
         * 清除选中的文件信息。
         * 该函数不接受任何参数，也不返回任何值。
         * 主要作用是重置文件预览、文件名和相关显示状态。
         */
        clearSelectedFile() {
            this.selectedFileName = ''; // 重置选中文件的名称
            this.originalSize = ''; // 重置选中文件的大小
            this.compressedSize = ''; // 重置选中文件压缩后的大小
            this.previewImage = ''; // 重置预览图像
            this.isImagePreviewVisible = false; // 隐藏图片预览
            this.isUploadBoxVisible = true; // 显示上传框
        },

        /**
         * 继续上传文件。
         * 该方法用于重置上传相关的状态和数据，以便进行下一次文件上传。
         */
        continueUploading() {
            // 隐藏上传结果
            this.isUploadResultVisible = false;
            // 重置上传的文件名、URL、路径
            this.uploadName = '';
            this.uploadUrl = '';
            this.uploadPath = '';
            // 清除选中的文件
            this.clearSelectedFile()
        },

        /**
         * 获取文件名的扩展名。
         * @param {string} fileName - 文件名，可以包含扩展名。
         * @return {string} 返回扩展名，始终为小写格式。
         */
        getFileExtension(fileName) {
            // 通过'.'分割文件名，获取扩展名
            const parts = fileName.split('.');
            // 移除文件名的最后一部分，即扩展名，并处理没有扩展名的情况
            const extension = parts.pop() || parts.pop();
            // 将扩展名转换为小写并返回
            return extension.toLowerCase();
        },

        /**
         * 获取当前的Date对象。这样做是为了减少代码重复和提高可维护性。
         * 注意：这个函数返回的是基于本地时间的Date对象。
         * @returns {Date} 当前的Date对象。
         */
        getCurrentDate() {
            return new Date();
        },

        /**
         * 获取当前时间的戳格式，格式为年月日时分秒的字符串。
         * 注意：这个函数使用的是本地时间，不考虑时区差异。
         * @returns {string} 返回的时间戳格式例如：20230131123000
         */
        getTimestamp() {
            const date = this.getCurrentDate();
            // 将当前时间的年、月、日、时、分、秒转换为字符串，并确保每个部分都是两位数的格式
            return [
                date.getFullYear(), // 获取当前年份
                String(date.getMonth() + 1).padStart(2, '0'), // 获取当前月份，需要转换为两位数格式
                String(date.getDate()).padStart(2, '0'), // 获取当前日，需要转换为两位数格式
                String(date.getHours()).padStart(2, '0'), // 获取当前小时，需要转换为两位数格式
                String(date.getMinutes()).padStart(2, '0'), // 获取当前分钟，需要转换为两位数格式
                String(date.getSeconds()).padStart(2, '0') // 获取当前秒数，需要转换为两位数格式
            ].join('');
        },

        /**
         * 获取当前的年份和月份。
         * 注意：这个函数使用的是本地时间，不考虑时区差异。
         * @returns {Object} 包含年份和月份的对象，月份从1开始。
         */
        getYearMonth() {
            const date = this.getCurrentDate();
            const year = `${date.getFullYear()}`;
            const month = String(date.getMonth() + 1).padStart(2, '0')
            return { year, month }
        },

        /**
         * 显示上传动画
         * 该函数创建并显示一个加载动画，用于指示文件上传过程。
         * 
         * @returns {Object} 返回加载动画的服务实例，允许进一步的操作和配置。
         */
        uploadAnimation() {
            // 创建并显示上传动画
            const uploadAnimation = ElLoading.service({
                text: 'UPLOAD...', // 动画文本内容
                spinner: 'el-icon-loading', // 动画图标
                background: 'rgba(0, 0, 0, 0.9)' // 背景颜色
            });
            return uploadAnimation;
        },

        /**
         * 异步上传图片到GitHub。
         * 该函数首先检查是否有图片数据可供上传，然后将图片数据（Base64格式）上传到GitHub，并更新组件状态以反映上传结果。
         */
        async uploadImage() {
            // 检查是否有图片数据可供上传
            if (!this.previewImage) {
                Notification.warning({
                    title: '警告',
                    message: '没有图片数据可上传',
                    type: 'warning'
                })
                return;
            }

            try {
                // 启动上传动画
                this.uploadAnimation()
                // 从Base64格式的图片数据中提取真正的图片数据
                const base64Data = this.previewImage.split(',')[1];

                // 从环境变量中读取GitHub访问所需的token、仓库名称和分支名称
                const token = process.env.VUE_APP_GITHUB_TOKEN;
                const repo = process.env.VUE_APP_GITHUB_REPO;
                const branch = process.env.VUE_APP_GITHUB_BRANCH;

                // 获取当前年份
                const year = this.getYearMonth().year;
                // 获取当前月份
                const month = this.getYearMonth().month;
                // 获取当前时间戳
                const timestamp = this.getTimestamp();
                // 构建文件路径
                const filePath = `Image-data-time/${year}/${month}/${timestamp}.${this.getFileExtension(this.selectedFileName)}`;

                // 将图片数据上传至GitHub
                const response = await this.uploadToGithub(base64Data, filePath, token, repo, branch);
                const uploadedFile = response.content;

                // 更新组件状态以反映上传结果
                this.uploadName = uploadedFile.name;
                this.uploadUrl = `https://jsd.012700.xyz/gh/${repo}/${uploadedFile.path}`;
                this.uploadPath = uploadedFile.path;
                this.isImagePreviewVisible = false;
                this.isUploadResultVisible = true;

                // 关闭加载动画
                this.uploadAnimation().close()
                // console.log(uploadedFile);
            } catch (error) {
                // 关闭加载动画
                this.uploadAnimation().close()
                // 如果上传过程中出现错误，显示错误通知
                this.handleError('上传图片失败:' + error)
            }
        },

        /**
         * 异步上传Base64编码的数据到Github指定仓库的文件路径
         * @param {string} base64Data Base64编码的文件内容
         * @param {string} filePath 在Github仓库中的文件路径
         * @param {string} token Github的个人访问令牌
         * @param {string} repo 仓库的名称，格式为"owner/repo"
         * @param {string} branch 要操作的分支名称
         * @returns {Promise<Object>} 返回一个Promise，成功时解析为Github API的响应数据
         */
        async uploadToGithub(base64Data, filePath, token, repo, branch) {
            // 使用fetch向Github API发送PUT请求来更新文件内容
            const response = await fetch(`https://api.github.com/repos/${repo}/contents/${filePath}?branch=${branch}`, {
                method: 'PUT',
                headers: {
                    Authorization: `token ${token}`, // 使用token进行授权
                    'Content-Type': 'application/json' // 指定请求体的类型为JSON
                },
                body: JSON.stringify({
                    message: 'Upload by PicBed', // 提交信息
                    content: base64Data,
                })
            });
            if (!response.ok) {
                // 请求失败时，显示错误通知并抛出错误
                this.handleError('上传到Github时发生错误，请打开控制台查看详情')
                throw new Error(`上传到Github时发生错误，状态码: ${response.status}`);
            }
            // 返回响应的JSON数据
            return await response.json();
        },
    },
    mounted() {
        /**
         * 创建一个ClipboardJS实例，为所有具有.copy-button类名的元素提供复制功能。
         * 
         * @param {String} '.copy-button' 选择器，指定具有复制功能的按钮。
         * @param {Object} options 配置对象。
         * @param {Function} options.text 复制文本的回调函数。
         * @returns {ClipboardJS} 返回一个ClipboardJS实例。
         */
        var clipboard = new ClipboardJS('.copy-button', {
            // 定义复制的文本为按钮前的文本内容
            text: (trigger) => {
                return trigger.previousElementSibling.innerText;
            },
        });

        // 当复制操作成功时触发的事件处理函数
        clipboard.on('success', function (e) {
            // 使用Notification显示成功的提示信息
            Notification.success({
                title: '成功', // 提示标题
                message: '已复制到剪切板', // 提示内容
                type: 'success' // 提示类型为成功
            })
        });

        /**
         * 监听复制操作失败的事件，并显示错误通知
         * @param {Object} e 事件对象，包含触发复制失败的元素信息
         */
        clipboard.on('error', function (e) {
            // 显示错误通知
            Notification.error({
                title: '错误',
                message: '复制失败，请尝试手动复制'
            })
            // 在控制台输出错误信息
            console.error('触发元素:', e.trigger);
        });
    },
    setup() {
        /**
         * 初始化并返回一个 ref 对象，其初始值为 8。
         * 此函数作为组件的 setup 函数，负责初始化组件的状态或数据，并返回需要在模板中使用的数据。
         * @returns {Object} 返回一个包含 radio 属性的对象，radio 是一个 ref 对象，初始值为 8。
         */
        const radio = ref(0.8)
        return { radio }
    },
};
</script>

<style scoped>
/* 基础样式 */
.upload-image {
    width: 60%;
    margin: 0 auto;
    /* 更改margin-left和margin-right为单一的margin，以简化代码 */
    display: block;
    /* 确保元素作为块级元素显示 */
}

/* 移动端适配 */
@media only screen and (max-width: 768px) {

    /* 选择一个适合的断点，比如平板电脑的宽度 */
    .upload-image {
        width: 100%;
        /* 在小屏幕上，让图片占据整个宽度 */
    }
}

/* 隐藏元素样式 */
.hidden {
    display: none;
}

/* 上传文件区域的样式 */
.upload-area {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 定义拖拽区域的样式 */
.drop-zone {
    border: 2px dashed #006de2;
    padding: 0 10px 20px;
    /* 调整内边距以适应不同屏幕尺寸 */
    border-radius: 8px;
    margin-bottom: 20px;
    cursor: pointer;
    text-align: center;
    position: relative;
    outline: none;
}

.drop-zone:hover {
    background-color: #f5f5f5;
}

.drop-zone img {
    width: 256px;
    /* 使图片宽度适应容器宽度 */
    height: auto;
    /* 自动调整高度以保持宽高比 */
    fill: #007bff;
}

.upload_text {
    color: #606266;
    font-size: 14px;
    text-align: center;
}

.upload_text em {
    color: #409eff;
    font-style: normal;
}

.upload_tip {
    font-size: 12px;
    color: #606266;
    margin-top: 7px;
}

/* 图片压缩 */
.compress-image {
    display: inline-block;
    margin: 0 auto;
}

.compress-mage-lv {
    margin-top: -4px;
    margin-right: 10px;
    color: #409eff;
}

.compress-mage-lv em {
    font-size: 12px;
    color: #606266;
    margin-top: 7px;
}

/* 移动端适配 */
@media only screen and (max-width: 768px) {
    .upload-area {
        padding: 10px;
    }

    .drop-zone {
        padding: 0 5 5px;
        /* 根据需要调整内边距 */
    }

    .el-radio-group {
        margin-top: 6px;
    }
}

/* 图片预览样式 */
.upload-result {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.file-info {
    margin-bottom: 16px;
    font-size: 16px;
    color: #333;
    /* 确保文本内容在小屏幕上也能换行 */
    word-wrap: break-word;
}

.upload-result .highlight {
    width: 97%;
    background-color: #000000;
    color: #ffffff;
    padding: 2px 5px;
    border-radius: 4px;
    display: inline-block;
    transition: background-color 0.3s ease;
}

.upload-result .highlight:hover {
    background-color: #ff0000;
}

.el-icon--right {
    margin-left: 0px;
}

.upload-button {
    padding: 2px 8px;
    font-size: 24px;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    float: right;
    margin-top: -14px;
    margin-right: 2%;
}

.upload-button:hover {
    background-color: #ff0000;
}

.delete-button {
    padding: 2px 8px;
    font-size: 24px;
    color: #ffffff;
    background-color: #ff0000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    float: right;
    margin-top: -14px;
    margin-right: 1%;
}

.delete-button:hover {
    background-color: #ffa500;
}

/* 移动端适配 */
@media only screen and (max-width: 768px) {
    .upload-result .highlight {
        width: 94%;
    }

    .upload-button {
        margin-top: -18px;
    }

    .delete-button {
        margin-top: -18px;
    }
}

/* 上传结果样式 */
.upload-return-result {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.continue-button {
    padding: 2px 8px;
    font-size: 24px;
    color: #ffffff;
    background-color: #c2e7b0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    float: right;
    margin-top: -36px;
    margin-right: 2%;
}

.continue-button:hover {
    background-color: #67c23a;
}

.upload-return-result h3 {
    margin: 0;
    font-size: 20px;
    margin-bottom: 10px;
}

.return-picture img {
    max-width: 100%;
    height: auto;
}

.return-picture {
    width: 50%;
    float: left;
    margin-right: 20px;
}

.return-content {
    overflow: auto;
    word-wrap: break-word;
}

/* 高亮结果样式 */
.upload-return-result .highlight {
    width: 94%;
    background-color: #000000;
    color: #ffffff;
    padding: 2px 5px;
    border-radius: 4px;
    display: inline-block;
    transition: background-color 0.3s ease;
}

.upload-return-result .highlight:hover {
    background-color: #ff0000;
}

.upload-return-result p:not(:first-child) {
    margin-top: 10px;
}

.upload-return-result p {
    margin: 0;
    line-height: 1.5;
    font-size: 16px;
    color: #333333;
    word-wrap: break-word;
}

.copy-button {
    padding: 1px 5px;
    font-size: 16px;
    color: #007bff;
    background-color: #0000;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    float: right;
    margin-top: 4px;
    margin-right: 4%;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* 移动端适配 */
@media only screen and (max-width: 768px) {

    .continue-button {
        margin-top: -44px;
    }

    .return-picture,
    .return-content {
        width: 100%;
        float: none;
        margin-right: 0;
    }

    .return-picture {
        margin-bottom: 20px;
    }

    .return-content {
        overflow: visible;
    }
}
</style>
